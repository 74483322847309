<template>
    <div class="p-2">

        <div class="">
            <div class="input-group search">
                <span class="input-group-text"><i class="bx bx-search"></i></span>
                <input type="text" class="form-control" placeholder="What are you looking for...">
            </div>
        </div>

        <div class="d-flex mt-2">
            <ul class="nav nav-pills w-100">
                <li class="nav-item flex-fill" style="max-width:50%">
                    <router-link class="nav-link py-1 font-weight-bold"
                        style="border-radius: 10px 0 0 10px;font-size: 16px;background-color: white;color:#1B628C;"
                        to="/shop/products">Products</router-link>
                </li>
                <li class="nav-item flex-fill" style="max-width:50%">
                    <router-link class="nav-link py-1 font-weight-bold"
                        style="border-radius: 0 10px 10px 0;font-size: 16px;background-color: #1EB4F1;color:white;"
                        to="/shop/shops">
                        Shops</router-link>
                </li>
            </ul>
        </div> 

        <div style="background-color:#57A9CB;border:1px solid #F29D38;border-radius:10px" class="p-1 mt-1"
            v-for="item in store_list">
            <div class="d-flex align-items-center" style="gap:10px">
                <div class="position-relative">
                    <img :src="item.store_picture"
                        style="height: 80px;width: 80px;background-color: #D9D9D9;border-radius: 10px;object-fit: cover;" />
                </div>
                <div class="flex-fill">
                    <h4 class="text-white font-weight-bolder mb-0">{{ item.store_name }}</h4>
                    <h6 class="text-white font-weight-normal" style="margin:.5em 0">{{ item.store_details }}</h6>
                </div>
            </div>
            <div>
                <label class="text-white h6 mt-1">Products</label>
                <div class="d-flex" style="gap:10px">
                    <a :href="prod.pr_hyperlink" target="_blank" v-for="prod in item.products_d">
                        <img :src="prod.pr_picture" style="height: 80px;width: 80px;background-color: #D9D9D9;border-radius: 10px;object-fit: cover;" />
                    </a>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

import store from '@/store'

export default {
    computed: {
        store_list() {
            return store.state.store_api.STORE_LIST || []
        }
    },
    mounted() {
        store.dispatch('store_api/GetStoreList', {

        })
    },
    data() {
        return {
        }
    }
}

</script>

<style>
.input-group.search span {
    border-right: unset;
    border-color: #FFAD32;
    border-radius: 10px 0 0 10px;
    font-size: 24px;
    color: #FFAD32;
    background-color: transparent;
}

.input-group.search input {
    margin-left: 0;
    padding-left: 0;
    border-left: unset;
    border-radius: 0 10px 10px 0;
    border-color: #FFAD32;
    height: 40px;
    color: white;
    background-color: transparent !important;
}
</style>